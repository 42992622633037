export default {
  // ENGLISH
  en: {
    translation: {
      descriptions: {
        table_module: 'Filter functions are coming soon.',
        home_module: 'Statistics-module is temporarily deactivated.',
        not_implemented: 'Not implemented yet.',
        table_module_durations:
          'You can click on one of the rows to hide a particular date from the calendar.',
      },
      labels: {
        host: 'Host',
        username: 'Username',
        calendar_days_limit: 'Number of calendar days to show',
        password: 'Password',
        price: 'Price',
        email: 'Email',
        phone: 'Phone',
        name: 'Name',
        booking: 'Booking',
        notificationMessage: 'SMS template',
        from: 'From',
        to: 'To',
        entity: 'Entity',
        select_language: 'Select language',
        from_date_time: 'From date/time',
        to_date_time: 'To date/time',
        available_spots: 'Available spots',
        repeat: 'Repeat',
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: 'Monthly',
        yearly: 'Yearly',
        every: 'Every',
        every_other: 'Every other',
        every_third: 'Every third',
        every_fourth: 'Every fourth',
        mo: 'MO',
        tu: 'TU',
        we: 'WE',
        th: 'TH',
        fr: 'FR',
        sa: 'SA',
        su: 'SU',
        number_of_days: 'Number of days',
        number_of_weeks: 'Number of weeks',
        number_of_months: 'Number of months',
        number_of_years: 'Number of years',
        timeslot_temp_name: 'Untitled',
        if_number_of_weeks_selected:
          'If you choose multiple days in a week the given value corresponds to number of days instead.',
      },
      buttons: {
        sign_in: 'Sign in',
        create_entity: 'Create new entity',
        show_customer_data: 'Show customer data',
        delete: 'Delete',
        save: 'Save',
        sign_out: 'Sign out',
        create_timeslot: 'Create new timeslot',
        copy: 'Copy',
        upload_photo: 'Upload photo',
      },
      tabs: {
        settings: 'Settings',
        timeslots: 'Timeslots',
        gallery: 'Gallery',
        calendar: 'Calendar',
        timeline: 'Timeline',
        table: 'Table',
        durations: 'Generated dates',
        create: 'Create',
        orders: 'Orders',
        entity_content: 'Description',
      },
      pages: {
        home: 'Dashboard',
        entities: 'Entities',
        bookings: 'Bookings',
        menu: 'Menu',
        customer_data: 'Customer data',
      },
      developed_by: 'Software developed by',
    },
  },
  // NORWEGIAN
  nb: {
    translation: {
      descriptions: {
        table_module: 'Filtrering kommer snart.',
        home_module: 'Statistikk-modul er midlertidlig deaktivert.',
        not_implemented: 'Ikke implementert, ennå.',
        table_module_durations:
          'Du kan klikke på en av radene for å skjule valgt dato fra kalenderen.',
      },
      labels: {
        host: 'Vert',
        username: 'Brukernavn',
        password: 'Passord',
        calendar_days_limit: 'Antall aktive kalenderdager',
        price: 'Pris',
        name: 'Navn',
        from: 'Fra',
        email: 'Epost',
        phone: 'Tlf',
        to: 'Til',
        entity: 'Enhet',
        notificationMessage: 'SMS mal',
        booking: 'Bestilling',
        select_language: 'Velg språk',
        from_date_time: 'Fra dato/tid',
        to_date_time: 'Til dato/tid',
        available_spots: 'Ledige plasser',
        repeat: 'Gjenta',
        daily: 'Daglig',
        weekly: 'Ukentlig',
        monthly: 'Månedlig',
        yearly: 'Årlig',
        every: 'Hver',
        every_other: 'Hver andre',
        every_third: 'Hver tredje',
        every_fourth: 'Hver fjerde',
        mo: 'MA',
        tu: 'TI',
        we: 'ON',
        th: 'TO',
        fr: 'FR',
        sa: 'LØ',
        su: 'SØ',
        number_of_days: 'Antall dager',
        number_of_weeks: 'Antall uker',
        number_of_months: 'Antall måneder',
        number_of_years: 'Antall år',
        if_number_of_weeks_selected:
          'Om du velger flere dager i uken vil verdien bli omgjort til antall dager.',
        timeslot_temp_name: 'Uten navn',
      },
      buttons: {
        sign_in: 'Logg inn',
        create_entity: 'Opprett ny enhet',
        show_customer_data: 'Vis kundedata',
        delete: 'Slett',
        save: 'Lagre',
        sign_out: 'Logg ut',
        create_timeslot: 'Opprett tidsrom',
        copy: 'Dupliser',
        upload_photo: 'Last opp bilde',
      },
      tabs: {
        settings: 'Innstillinger',
        timeslots: 'Tidsrom',
        gallery: 'Galleri',
        calendar: 'Kalender',
        timeline: 'Tidslinje',
        table: 'Tabell',
        durations: 'Genererte datoer',
        create: 'Opprett',
        orders: 'Ordrer',
        entity_content: 'Beskrivelse',
      },
      pages: {
        home: 'Dashbord',
        entities: 'Enheter',
        bookings: 'Bestillinger',
        menu: 'Meny',
        customer_data: 'Kundedata',
      },
      developed_by: 'Programvare utviklet av',
    },
  },
  // NEW NORWEGIAN
  nn: {
    translation: {
      descriptions: {
        table_module: 'Filtrering kjem snart.',
        home_module: 'Statstikk-modul er midlertidig deaktivert.',
        not_implemented: 'Ikkje implementert, ennå.',
        table_module_durations:
          'Du kan klikke på en av radene for å skjule valgt dato fra kalenderen.',
      },
      labels: {
        host: 'Vert',
        username: 'Brukernamn',
        password: 'Passord',
        price: 'Pris',
        name: 'Namn',
        email: 'Epost',
        phone: 'Tlf',
        from: 'Fra',
        to: 'Til',
        entity: 'Eining',
        booking: 'Bestilling',
        select_language: 'Velg språk',
        notificationMessage: 'SMS mal',
        from_date_time: 'Frå dato/tid',
        to_date_time: 'Til dato/tid',
        available_spots: 'Ledige plasser',
        repeat: 'Gjenta',
        daily: 'Dagleg',
        weekly: 'Vekentleg',
        monthly: 'Månedleg',
        yearly: 'Årleg',
        every: 'Kvar',
        every_other: 'Kvar andre',
        every_third: 'Kvar tredje',
        every_fourth: 'Kvar fjerde',
        mo: 'MÅ',
        tu: 'TI',
        we: 'ON',
        th: 'TO',
        fr: 'FR',
        sa: 'LØ',
        su: 'SØ',
        number_of_days: 'Antall dagar',
        number_of_weeks: 'Antall veker',
        number_of_months: 'Antall månedar',
        number_of_years: 'Antall år',
        if_number_of_weeks_selected:
          'Dersom du vel fleire dagar i veka vil verdien bli omgjort til antall dagar',
        timeslot_temp_name: 'Utan namn',
      },
      buttons: {
        sign_in: 'Logg inn',
        create_entity: 'Opprett ny eining',
        show_customer_data: 'Vis kundedata',
        delete: 'Slett',
        save: 'Lagre',
        sign_out: 'Logg ut',
        create_timeslot: 'Opprett tidsrom',
        copy: 'Dupliser',
        upload_photo: 'Last opp bilde',
      },
      tabs: {
        settings: 'Innstillingar',
        timeslots: 'Tidsrom',
        gallery: 'Galleri',
        calendar: 'Kalender',
        timeline: 'Tidslinje',
        table: 'Tabell',
        durations: 'Genererte datoar',
        create: 'Opprett',
        orders: 'Ordrer',
        entity_content: 'Beskrivelse',
      },
      pages: {
        home: 'Heim',
        entities: 'Einingar',
        bookings: 'Bestillingar',
        menu: 'Meny',
        customer_data: 'Kundedata',
      },
      developed_by: 'Programvare utvikla av',
    },
  },
  // DEUTSCHE
  de: {
    translation: {
      descriptions: {
        table_module: 'Filterfunktion in Kürze verfügbar.',
        home_module: 'Statistik ist vorübergehend deaktiviert.',
        not_implemented: 'Noch nicht implementiert.',
        table_module_durations:
          'Sie können auf eine der Zeilen klicken, um ein bestimmtes Datum aus dem Kalender auszublenden.',
      },
      labels: {
        host: 'Host',
        username: 'Nutzername',
        password: 'Passwort',
        price: 'Preis',
        name: 'Name',
        from: 'Aus',
        to: 'Zu',
        email: 'Email',
        phone: 'Telefonnummer',
        entity: 'Einheit',
        booking: 'Bestellen',
        select_language: 'Sprache auswählen',
        from_date_time: 'Von Datum/Uhrzeit',
        to_date_time: 'Bis Datum/Uhrzeit',
        available_spots: 'Verfügbare Plätze',
        repeat: 'Wiederholen',
        daily: 'Täglich',
        weekly: 'Wöchentlich',
        monthly: 'Monatlich',
        yearly: 'Jährlich',
        every: 'Jeder',
        every_other: 'Jeder andere',
        every_third: 'Jeder dritte',
        every_fourth: 'Jeder vierte',
        mo: 'MO',
        tu: 'DI',
        we: 'MI',
        th: 'DO',
        fr: 'FR',
        sa: 'SA',
        su: 'SO',
        number_of_days: 'Anzahl der Tage',
        number_of_weeks: 'Anzahl der Wochen',
        number_of_months: 'Anzahl der Monate',
        number_of_years: 'Anzahl von Jahren',
        if_number_of_weeks_selected:
          'Wenn Sie mehrere Tage in einer Woche auswählen, entspricht der angegebene Wert stattdessen der Anzahl der Tage.',
        timeslot_temp_name: 'Untitled',
      },
      buttons: {
        sign_in: 'Einloggen',
        create_entity: 'Erstellen Sie eine neue Entität',
        show_customer_data: 'Kundendaten anzeigen',
        delete: 'Löschen',
        save: 'Speichern',
        sign_out: 'Ausloggen',
        create_timeslot: 'Erstellen Sie ein neues Zeitfenster',
        copy: 'Duplikat',
        upload_photo: 'Bild hochladen',
      },
      tabs: {
        settings: 'Einstellungen',
        timeslots: 'Zeitfenster',
        gallery: 'Galerie',
        calendar: 'Kalender',
        timeline: 'Zeitleiste',
        table: 'Tisch',
        durations: 'Generierte Daten',
        create: 'Schaffen',
        orders: 'Aufträge',
      },
      pages: {
        home: 'Heimat',
        entities: 'Entitäten',
        bookings: 'Buchungen',
        menu: 'Menü',
      },
      developed_by: 'Software entwickelt von',
    },
  },
  fr: {
    translation: {
      descriptions: {
        table_module: 'Les fonctions de filtrage arrivent bientôt.',
        home_module: 'Le module de statistiques est temporairement désactivé.',
        not_implemented: 'Pas encore mis en œuvre.',
        table_module_durations:
          "Vous pouvez cliquer sur l'une des lignes pour masquer une date particulière du calendrier.",
      },
      labels: {
        host: 'Host',
        username: "Nom d'utilisateur",
        password: 'Mot de passe',
        price: 'Mot de passe',
        email: 'Envoyer un e-mail',
        phone: 'Téléphoner',
        name: 'Nom',
        booking: 'Réservation',
        from: 'À partir',
        to: 'Pour',
        entity: 'Entité',
        select_language: 'Choisir la langue',
        from_date_time: 'Partir de la date/moment',
        to_date_time: 'À ce jour/moment',
        available_spots: 'Spots disponibles',
        repeat: 'Répéter',
        daily: 'du quotidien',
        weekly: 'Hebdomadaire',
        monthly: 'Mensuelle',
        yearly: 'Annuel',
        every: 'Tous',
        every_other: 'Tous les autres',
        every_third: 'Tous les tiers',
        every_fourth: 'Chaque quatrième',
        mo: 'LU',
        tu: 'MA',
        we: 'ME',
        th: 'JE',
        fr: 'VE',
        sa: 'SA',
        su: 'DI',
        number_of_days: 'Nombre de jours',
        number_of_weeks: 'Nombre de semaines',
        number_of_months: 'Nombre de mois',
        number_of_years: "Nombre d'années",
        timeslot_temp_name: 'Sans titre',
        if_number_of_weeks_selected:
          'Si vous choisissez plusieurs jours en une semaine, la valeur donnée correspond au nombre de jours à la place.',
      },
      buttons: {
        sign_in: "S'identifier",
        create_entity: 'Créer une nouvelle entité',
        show_customer_data: 'Afficher les données clients',
        delete: 'Effacer',
        save: 'Sauvegarder',
        sign_out: 'Déconnexion',
        create_timeslot: 'Créer un nouveau créneau horaire',
        copy: 'Copie',
        upload_photo: 'Envoyer la photo',
      },
      tabs: {
        settings: 'Les paramètres',
        timeslots: 'Tranches de temps',
        gallery: 'Galerie',
        calendar: 'Calendrier',
        timeline: 'Chronologie',
        table: 'Table',
        durations: 'Dates générées',
        create: 'Créer',
        orders: 'Ordres',
      },
      pages: {
        home: 'Maison',
        entities: 'Entités',
        bookings: 'Réservations',
        menu: 'Menu',
      },
      developed_by: 'Logiciel développé par',
    },
  },
};
