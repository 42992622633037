import React, { useEffect, useRef, useState } from "react";
import { Button, MenuItem, Select, Box, Typography } from "@mui/material";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import styles from "./Dashboard.module.css";
import StatsCard from "./StatsCard";
import { useTranslation } from "react-i18next";

// Icons
import { FiBarChart2, FiPackage } from "react-icons/fi";
import { useAppStore } from "../../App";
import { ResponsivePie } from "@nivo/pie";
import useBookingRepositoryHook from "../../booking-repository-hook";

const DashboardModule = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let { t } = useTranslation();
  let [lang, setLang] = useState("0");
  let { user } = useAppStore((state) => state);
  let [chartHeight, setChartHeight] = useState<number>(0);
  let containerRef = useRef<HTMLDivElement>(null);
  let [chartData, setChartData] = useState(null);
  let { getDailyBookingCountThisWeek } = useBookingRepositoryHook();

  useEffect(() => {
    if (containerRef.current != null) {
      setChartHeight(containerRef.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    if (chartData == null && user) {
      getDailyBookingCountThisWeek().then((data: any) => setChartData(data));
    }
  }, [user]);

  useEffect(() => {
    if (!user) {
      navigate("/signin");
    }
  }, [user]);

  useEffect(() => {
    if (lang != "0") {
      window.localStorage.setItem("i18nextLng", lang);
    }
  }, [lang]);

  return (
    <div className={styles.common}>
      <div className={styles.header}>
        <h1 className={styles.h1}>{t("pages.home")}</h1>
        {/* <Select
          value={lang}
          onChange={({ target }: any) => setLang(target.value)}
        >
          <MenuItem value="0">{t("labels.select_language")}</MenuItem>
          <MenuItem value="no">Norsk Bokmål</MenuItem>
          <MenuItem value="nn">Norsk Nynorsk</MenuItem>
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="de">Deutsche</MenuItem>
        </Select> */}
      </div>
      <div ref={containerRef} className={styles.content}>
        {chartHeight > 0 && chartData && (chartData as any).length > 0 && (
          <Box>
            <Typography fontSize="18px" textAlign="center">
              Bookinger denne uken.
            </Typography>
            <MyResponsivePie height={chartHeight} data={chartData} />
          </Box>
        )}
        {chartData && (chartData as any).length == 0 && (
          <div className={styles.h3}>Fant ingen bookinger denne uken.</div>
        )}
        {/* <StatsCard
          icon={<FiPackage size="24px" />}
          label="Bestillinger"
          value="42"
        />
        <StatsCard
          icon={<FiBarChart2 size="24px" />}
          label="Omsetning"
          value="32 890kr"
        /> */}
      </div>
    </div>
  );
};

let pieTheme = {
  labels: {
    text: {
      fontSize: 24,
    },
  },
};

const MyResponsivePie = ({
  data,
  height,
}: {
  data: Array<{ id: string; label: string; value: number; color: string }>;
  height: number;
}) => (
  <div style={{ height: `${height * 0.7}px` }}>
    <ResponsivePie
      data={data}
      theme={pieTheme}
      margin={{ top: 80, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      colors={{ scheme: "set3" }}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsOffset={5}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      /* legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 56,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]} */
    />
  </div>
);

export default DashboardModule;
