import Entity from "./Entity";

export default class Image {
  id: string | undefined;
  imageFileType: string | undefined;
  resourceUri: string | undefined;
  imageDataUrl: string | undefined;

  static fromJson(json: any): Image {
    let img = new Image();
    img.id = json["id"];
    img.imageFileType = json["imageFileType"];
    img.resourceUri = json["resourceUri"];

    if (this && "host" in this) {
      img.resourceUri = (this as any).host + img.resourceUri;
    }
    img.imageDataUrl = json["imageDataUrl"];

    return img;
  }

  async createOrUpdate(
    e: Entity,
    host: string,
    jwtToken: string
  ): Promise<Image> {
    let response = await fetch(`${host}/api/entities/${e.id}/images`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(this),
    });

    let result = await response.json();

    if (response.status != 200) throw new Error("Could not upload image!");

    return Image.fromJson.bind({ host })(result);
  }
}
