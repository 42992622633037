import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Link,
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Icons
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CategoryIcon from '@mui/icons-material/Category';
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';

// Icons (Feather)
import { FiHome, FiBox, FiCalendar, FiUser, FiMenu } from 'react-icons/fi';

import create from 'zustand';

// Modules
import EntityModule from './Modules/Entity';
import Home from './Modules/Entity/Home';
import Settings from './Modules/Entity/Settings';
import NavButton from './Components/NavButton/NavButton';
import DashboardModule from './Modules/Dashboard';
import MenuModule from './Modules/Menu';
import BookingModule from './Modules/Booking';
import CreateEntity from './Modules/Entity/Create';
import EditEntity from './Modules/Entity/EditEntity';
import Timeslots from './Modules/Entity/ListTimeslots';
import TimeslotModule from './Modules/Timeslot';
import EditTimeslot from './Modules/Timeslot/EditTimeslot';
import Durations from './Modules/Timeslot/Durations';
import Gallery from './Modules/Entity/Gallery';
import Timeline from './Modules/Booking/Timeline';
import Overview from './Modules/Booking/Overview';
import BookingCard from './Modules/Booking/BookingCard';
import EditBooking from './Modules/Booking/EditBooking';
import BookingSettings from './Modules/Booking/Settings';
import EntityTimeslotsCalendar from './Modules/Entity/EntityTimeslotsCalendar';
import AuthModule from './Modules/Auth';
import User from './Models/User';
import Customer from './Modules/Customer';
import TableView from './Modules/Booking/TableView';

// i18n
import { useTranslation } from 'react-i18next';
import SelectPanel from './Modules/Menu/SelectPanel';
import Accounts from './Modules/Menu/Accounts';
import ContentEditor from './Modules/Entity/ContentEditor';

const queryClient = new QueryClient();
// Theming
const theme = createTheme({
  palette: {
    primary: {
      main: '#005f76',
    },
  },
});

interface AppState {
  host?: string;
  user?: User;
  setUser: (u: User) => void;
  signOut: () => void;
  setHost: (host: string) => void;
}

export const useAppStore = create<AppState>((set: any) => ({
  signOut: () => {
    set({ user: null });
    window.localStorage.removeItem('token');
  },
  setUser: (user: User) => {
    set({ user });
    console.log(user);
  },
  setHost: (host: string) => set({ host }),
}));

const AppLayout = () => {
  const { t } = useTranslation();
  let location = useLocation();
  let navigate = useNavigate();
  let { user } = useAppStore((state) => state);

  return (
    <div className="wrapper">
      <div className="app">
        <main>
          <Outlet />
        </main>
        <nav>
          <NavButton
            selected={location.pathname == '/'}
            label={t('pages.home')}
            onClick={() => navigate('/')}
            icon={<FiHome size="28px" />}
          />
          {user && user.hasRole('ROLE_ADMIN') && (
            <NavButton
              selected={location.pathname.includes('entities')}
              label={t('pages.entities')}
              onClick={() => navigate('/entities')}
              icon={<FiBox size="28px" />}
            />
          )}
          <NavButton
            selected={location.pathname.includes('bookings')}
            label={t('pages.bookings')}
            onClick={() => navigate('/bookings/overview')}
            icon={<FiCalendar size="28px" />}
          />
          <NavButton
            selected={location.pathname.includes('menu')}
            label={t('pages.menu')}
            onClick={() => navigate('/menu')}
            icon={<FiMenu size="28px" />}
          />
        </nav>
      </div>
    </div>
  );
};

function App() {
  let { user, setUser } = useAppStore((state) => state);

  useEffect(() => {
    let token = window.localStorage.getItem('token');

    if (token && !user) {
      User.getUserFromJwtToken(token)
        .then((user: User) => setUser(user))
        .catch((err: Error) => window.localStorage.removeItem('token'));
    }
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/signin" element={<AuthModule />} />
            <Route path="/" element={<AppLayout />}>
              <Route index element={<DashboardModule />} />
              <Route path="entities" element={<EntityModule />}>
                <Route index element={<Home />} />
                <Route path="settings" element={<Settings />}>
                  <Route index element={<EditEntity />} />
                  <Route path="timeslots" element={<Timeslots />} />
                  <Route path="gallery" element={<Gallery />} />
                  <Route
                    path="calendar"
                    element={<EntityTimeslotsCalendar />}
                  />
                  <Route path="content-editor" element={<ContentEditor />} />
                </Route>
                <Route path="create" element={<CreateEntity />} />
              </Route>
              <Route path="bookings" element={<BookingModule />}>
                <Route path="overview" element={<Overview />}>
                  <Route index element={<Timeline />} />
                  <Route path="tableview" element={<TableView />} />
                  {/* <Route path="calendar" element={<BookingCard />} /> */}
                </Route>
                <Route path="settings" element={<BookingSettings />}>
                  <Route index element={<EditBooking />} />
                </Route>
              </Route>
              <Route path="menu" element={<MenuModule />} />
              <Route path="timeslot/:id" element={<TimeslotModule />}>
                <Route index element={<EditTimeslot />} />
                <Route path="durations" element={<Durations />} />
              </Route>
              <Route path="customer" element={<Customer />} />
              <Route path="menu" element={<MenuModule />}>
                <Route index element={<SelectPanel />} />
                <Route path="accounts" element={<Accounts />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
