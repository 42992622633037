import {
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { format } from "date-fns";
import {
  useLocation,
  useNavigate,
  useOutlet,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Booking from "../../Models/Booking";
import { useTranslation } from "react-i18next";
import Timeslot from "../../Models/Timeslot";
import Duration from "../../Models/Duration";
import styles from "./Timeslot.module.css";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useBookingRepositoryHook from "../../booking-repository-hook";

export default function Durations() {
  const queryClient = useQueryClient();
  let { id } = useParams<{ id: string }>();
  let { t } = useTranslation();
  let [selectedDate, setDate] = useState<Date | null>(null);
  let [ignoredDates, setIgnoredDates] = useState<Date[]>([]);
  let { getTimeslotById, updateTimeslotByIdAndFieldValue } =
    useBookingRepositoryHook();

  let { data, isLoading } = useQuery(["timeslot"], () => getTimeslotById(id!));

  useEffect(() => console.log(data), [data]);

  let mutation = useMutation(
    (ignoredDates: Date[]) =>
      updateTimeslotByIdAndFieldValue(id!, { ignoredDates }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["timeslot"]);
      },
    }
  );

  return (
    <Box>
      <Box p={4} display="flex" justifyContent="center" alignItems="center">
        <Typography fontSize="14px" fontStyle="italic">
          {t("descriptions.table_module_durations")}
        </Typography>
      </Box>
      <IgnoreDurationDialog
        open={selectedDate != null}
        onSubmit={() => {
          if (
            data &&
            "ignoredDates" in data &&
            data.ignoredDates != null &&
            selectedDate != null
          ) {
            console.log([...data.ignoredDates, selectedDate]);
            mutation.mutate([...data.ignoredDates, selectedDate]);
            setDate(null);
          }
        }}
        onClose={() => setDate(null)}
        label={"Er du sikker på at du ønsker å fjerne valgt dato?"}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableCell>{t("labels.from")}</TableCell>
            <TableCell>{t("labels.to")}</TableCell>
          </TableHead>
          <TableBody>
            {isLoading && <CircularProgress />}
            {data &&
              data
                .durations!.sort(
                  (a: Duration, b: Duration) =>
                    a.startDate!.getTime() - b.startDate!.getTime()
                )
                .filter(
                  (duration: Duration) =>
                    !ignoredDates.some(
                      (d) => d.getTime() == duration.startDate?.getTime()
                    )
                )
                .map((duration: Duration) => (
                  <TableRow
                    className={styles.tableRow}
                    onClick={() => setDate(duration.startDate!)}
                  >
                    <TableCell style={{}}>
                      {format(duration!.startDate!, "yyyy-MM-dd HH:mm")}
                    </TableCell>
                    <TableCell>
                      {format(duration!.endDate!, "yyyy-MM-dd HH:mm")}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function IgnoreDurationDialog({
  open,
  label,
  onSubmit,
  onClose,
}: {
  open: boolean;
  label: string;
  onSubmit: () => void;
  onClose: () => void;
}) {
  return (
    <Dialog open={open}>
      <DialogTitle>{label}</DialogTitle>
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }} p={2}>
        <Button variant="contained" onClick={onSubmit}>
          Ja
        </Button>
        <Button variant="contained" onClick={onClose}>
          Nei
        </Button>
      </Box>
    </Dialog>
  );
}
