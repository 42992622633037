import React from 'react';
import {
  TextField,
  Box,
  Typography,
  Button,
  CardMedia,
  Card,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { useState } from 'react';
import Entity from '../../Models/Entity';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useBookingRepositoryHook from '../../booking-repository-hook';

export default function EditEntity() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location: any = useLocation();
  let [currentImage, setCurrentImage] = useState<string | null>(null);
  let { createOrUpdateEntity, deleteEntity } = useBookingRepositoryHook();
  let { t } = useTranslation();

  const mutation = useMutation(createOrUpdateEntity, {
    onSuccess: () => {
      queryClient.invalidateQueries(['entities']);
      navigate('/entities');
    },
  });

  const mutationDelete = useMutation(deleteEntity, {
    onSuccess: () => {
      queryClient.invalidateQueries(['entities']);
      navigate('/entities');
    },
  });

  let [name, setName] = useState<string | null>(location.state.name);
  let [price, setPrice] = useState<string | null>(location.state.price);

  const onSave = () => {
    mutation.mutate(
      Entity.fromJson({
        id: location.state.id,
        name,
        price: price ? Number.parseInt(price) : 0,
        imageDataUrlType: currentImage?.split(',')[0],
        imageDataUrl: currentImage?.split(',')[1],
      })
    );
  };

  const onDelete = () => {
    mutationDelete.mutate(
      Entity.fromJson({
        id: location.state.id,
        name,
        price: price ? Number.parseInt(price) : 0,
      })
    );
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Card sx={{ marginBottom: 2, position: 'relative' }}>
          {currentImage && (
            <CardMedia
              component="img"
              sx={{
                width: '100%',
                height: 120,
                opacity: currentImage ? 1 : 0.5,
              }}
              src={currentImage}
            />
          )}
          {!currentImage && (
            <CardMedia
              component="img"
              sx={{
                width: 200,
                height: 200,
                opacity: 1,
              }}
              src={
                location.state.imageUri
                  ? `${location.state.imageUri}`
                  : '/images/nophoto.jpg'
              }
            />
          )}
          {/* <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            margin: "-20px 0 0 -20px",
            color: "white",
          }}
        >
          {!currentImage && (
            <PhotoSelector
              onSelect={({ type, dataUrl }) => {
                if (dataUrl.length > 0) {
                  console.log(type);
                  setCurrentImage(`${type},${dataUrl}`);
                }
              }}
            />
          )}
        </Box> */}
        </Card>
      </Box>
      <TextField
        placeholder="ID"
        label="ID"
        disabled
        variant="filled"
        sx={{ width: '100%', marginBottom: 2 }}
        value={location.state.id}
        onChange={({ target }) => setName(target.value)}
      />
      <TextField
        label={t('labels.name')}
        variant="filled"
        sx={{ width: '100%', marginBottom: 2 }}
        value={name}
        onChange={({ target }) => setName(target.value)}
      />
      <TextField
        label={t('labels.price')}
        variant="filled"
        sx={{ width: '100%', marginBottom: 2 }}
        value={price}
        onChange={({ target }) => setPrice(target.value)}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onSave} startIcon={<SaveIcon />}>
          {t('buttons.save')}
        </Button>
        <Button onClick={onDelete} color="error" startIcon={<DeleteIcon />}>
          {t('buttons.delete')}
        </Button>
      </Box>
    </Box>
  );
}
