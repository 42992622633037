import styles from './Auth.module.css';
import {
    Button,
    CircularProgress,
    FormControl,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import featured from './featured.svg';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import User from '../../Models/User';
import { useAppStore } from '../../App';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import knownHosts from './known_hosts.json';

export default function AuthModule() {
    let { t } = useTranslation();
    let { host, user, setHost, setUser } = useAppStore((state) => state);
    let navigate = useNavigate();
    let [username, setUsername] = useState('');
    let [password, setPassword] = useState('');
    let [isLoading, setIsLoading] = useState(false);
    let [lang, setLang] = useState('0');
    let [queryParams] = useSearchParams();

    useEffect(() => {
        if (lang != '0') {
            window.location.assign('/signin?lng=' + lang);
        }
    }, [lang]);

    useEffect(() => {
        let storedHost = window.localStorage.getItem('host');
        let hostQuery = queryParams.get('host');

        if (hostQuery) {
            setHost(atob(hostQuery));
        } else if (storedHost) {
            setHost(storedHost);
        }
    }, []);

    useEffect(() => {
        if (user) {
            setIsLoading(true);
            new Promise((res) =>
                setTimeout(() => {
                    res(true);
                }, 1000)
            ).then(() => navigate('/'));
        }
    }, [user]);

    useEffect(() => {
        let match = knownHostMatch(host ?? '');

        if (match) {
            window.localStorage.setItem('host', match);
            setHost(match);
        } else {
            window.localStorage.setItem('host', host ?? '');
        }
    }, [host]);

    const handleSubmit = () =>
        User.signInWithUsernameAndPassword(host!, username, password)
            .then((user: User) => {
                window.localStorage.setItem('token', user.jwtToken!);
                setIsLoading(true);
                return user;
            })
            .then(
                (user: User) =>
                    new Promise<User>((res) =>
                        setTimeout(() => res(user), 1000)
                    )
            )
            .then(setUser);

    return (
        <div className={styles.wrapper}>
            <div className={styles.auth}>
                <div className={styles.featured}>
                    <img src={featured} className={styles.svg} />
                </div>
                <div className={styles.form}>
                    <TextField
                        value={username}
                        onChange={({ target }: any) =>
                            setUsername(target.value)
                        }
                        sx={{ marginBottom: 2 }}
                        label={t('labels.username')}
                        variant="filled"
                    />
                    <TextField
                        value={password}
                        onChange={({ target }: any) =>
                            setPassword(target.value)
                        }
                        sx={{ marginBottom: 3 }}
                        label={t('labels.password')}
                        type="password"
                        variant="filled"
                    />
                    <TextField
                        value={host ?? ''}
                        onChange={({ target }: any) => setHost(target.value)}
                        sx={{ marginBottom: 2 }}
                        label={t('labels.host')}
                        type="text"
                        size="small"
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            marginTop: '10px',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Select
                            value={lang}
                            onChange={({ target }: any) =>
                                setLang(target.value)
                            }
                            size="small"
                        >
                            <MenuItem value="0">
                                {t('labels.select_language')}
                            </MenuItem>
                            <MenuItem value="nb">Norsk Bokmål</MenuItem>
                            <MenuItem value="nn">Norsk Nynorsk</MenuItem>
                            <MenuItem value="en">English</MenuItem>
                            <MenuItem value="de">Deutsche</MenuItem>
                            <MenuItem value="fr">Français</MenuItem>
                        </Select>
                        <Button
                            onClick={handleSubmit}
                            disabled={isLoading}
                            variant="contained"
                        >
                            {!isLoading ? (
                                t('buttons.sign_in')
                            ) : (
                                <CircularProgress
                                    sx={{ color: 'black' }}
                                    size={26}
                                />
                            )}
                        </Button>
                    </Box>
                </div>
                <Typography
                    fontSize="14px"
                    color="#00000088"
                    textAlign="center"
                    marginTop="-10px"
                >
                    {t('developed_by')}{' '}
                    <a
                        className={styles.link}
                        target="_blank"
                        href="https://omega365design.no"
                    >
                        Omega 365 Design
                    </a>
                </Typography>
            </div>
            <Typography className={styles['app-version']}>
                v2.7.0-lofoten-edition
            </Typography>
        </div>
    );
}

function knownHostMatch(hostAlias: string): string | null {
    let hosts: any = knownHosts;
    if (hostAlias in hosts) {
        return hosts[hostAlias];
    }
    return null;
}
