import React from "react";
import { TextField, Box, Typography, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Add";
import { useState } from "react";
import Entity from "../../Models/Entity";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useBookingRepositoryHook from "../../booking-repository-hook";

export default function CreateEntity() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let { t } = useTranslation();
  let { createOrUpdateEntity } = useBookingRepositoryHook();

  const mutation = useMutation(createOrUpdateEntity, {
    onSuccess: () => {
      queryClient.invalidateQueries(["entities"]);
      navigate("/entities");
    },
  });

  let [name, setName] = useState<string | null>("");
  let [price, setPrice] = useState<string | null>("");

  const onSave = () => {
    mutation.mutate(
      Entity.fromJson({
        name,
        price: price ? Number.parseInt(price) : 0,
      })
    );
  };

  return (
    <Box>
      <Typography
        component="div"
        sx={{ fontSize: 30, fontWeight: 400, marginBottom: 2 }}
      >
        {t("buttons.create_entity")}
      </Typography>
      <TextField
        placeholder={t("labels.name")}
        variant="filled"
        sx={{ width: "100%", marginBottom: 2 }}
        value={name}
        onChange={({ target }) => setName(target.value)}
      />
      <TextField
        placeholder={t("labels.price")}
        variant="filled"
        sx={{ width: "100%", marginBottom: 2 }}
        value={price}
        onChange={({ target }) => setPrice(target.value)}
      />
      <Button onClick={onSave} startIcon={<SaveIcon />}>
        {t("buttons.save")}
      </Button>
    </Box>
  );
}
