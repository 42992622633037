import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import { RiShieldUserLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

function SelectPanel() {
  let navigate = useNavigate();

  return (
    <>
      <Box
        display="grid"
        gridTemplateRows="1fr 1fr 1fr"
        gridTemplateColumns="1fr 1fr 1fr 1fr"
        gap="20px"
      >
        {/* <SettingsCard
          title="Kontoadministrasjon"
          onOpen={() => navigate("/menu/accounts")}
        /> */}
      </Box>
    </>
  );
}

function SettingsCard({
  title,
  onOpen,
}: {
  title: string;
  onOpen: () => void;
}) {
  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="center" alignItems="center">
          <RiShieldUserLine size={50} />
        </Box>
        <Typography
          mt={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontWeight="500"
        >
          {title}
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={onOpen}>Gå til panel</Button>
      </CardActions>
    </Card>
  );
}

export default SelectPanel;
