import React, { ReactNode } from "react";
import styles from "./NavButton.module.css";

interface NavButtonProps {
  label: string;
  icon: ReactNode;
  selected?: boolean;
  onClick: () => void;
}

const NavButton: React.FC<NavButtonProps> = ({
  label,
  icon,
  selected = false,
  onClick,
}) => (
  <div
    onClick={onClick}
    className={[styles.button, selected ? styles.selected : null].join(" ")}
  >
    <div>{icon}</div>
    <div className={styles.text}>{label}</div>
  </div>
);

export default NavButton;
