import React from 'react';
import { Typography, Box, IconButton, Tabs, Tab } from '@mui/material';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import Entity from '../../Models/Entity';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';

export default function Settings() {
  let navigate = useNavigate();
  let location: any = useLocation();
  let { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography component="div" sx={{ fontSize: 40, fontWeight: 400 }}>
          {location.state.name}
        </Typography>
      </Box>
      <Tabs value={location.pathname} sx={{ marginBottom: 2 }}>
        <Tab
          label={t('tabs.settings')}
          value="/entities/settings"
          onClick={() =>
            navigate('/entities/settings', { state: location.state })
          }
        ></Tab>
        <Tab
          label={t('tabs.timeslots')}
          value="/entities/settings/timeslots"
          onClick={() =>
            navigate('/entities/settings/timeslots', { state: location.state })
          }
        ></Tab>
        <Tab
          label={t('tabs.gallery')}
          value="/entities/settings/gallery"
          onClick={() =>
            navigate('/entities/settings/gallery', { state: location.state })
          }
        ></Tab>
        <Tab
          label={t('tabs.entity_content')}
          value="/entities/settings/content-editor"
          onClick={() =>
            navigate('/entities/settings/content-editor', {
              state: location.state,
            })
          }
        ></Tab>
        {/*         <Tab
          label={t('tabs.calendar')}
          value="/entities/settings/calendar"
          onClick={() =>
            navigate('/entities/settings/calendar', { state: location.state })
          }
        ></Tab> */}
      </Tabs>
      <Outlet />
    </Box>
  );
}
