import Timeslot from './Timeslot';
import Image from './Image';
import Entity from './Entity';

export default class Booking {
    id: number | undefined;
    fromDate: Date | undefined;
    toDate: Date | undefined;
    entity: Entity | undefined;
    customer: any | undefined;
    metaData: String[] | undefined;

    static fromJson(json: any): Booking {
        let e = new Booking();
        e.id = json['id'];
        e.fromDate = new Date(json['fromDate']);
        e.toDate = new Date(json['toDate']);

        if (this && 'host' in this) {
            e.entity = Entity.fromJson.bind({ host: (this as any).host })(
                json['entity']
            );
        } else {
            e.entity = Entity.fromJson(json['entity']);
        }

        e.customer = json['customer'];
        e.metaData = json['metaData'];

        return e;
    }

    static async getAll(host: string, jwtToken: string): Promise<Booking[]> {
        let response = await fetch(`${host}/api/bookings`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`,
            },
        });

        let result = await response.json();

        return result.map((e: any) => Booking.fromJson.bind({ host })(e));
    }

    /* static async createOrUpdateEntity(e: Booking): Promise<Booking> {
    let response = await fetch(`${process.env.REACT_APP_HOST}/api/b`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(e),
    });

    let result = await response.json();

    return Entity.fromJson(result);
  } */

    /* async delete(): Promise<void> {
    let response = await fetch(
      `${process.env.REACT_APP_HOST}/api/entities/${this.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    let result = await response.json();

    if (!("success" in result)) {
      throw new Error("Could not delete Entity!");
    }
  } */
}
