import React from 'react';
import Booking from '../../Models/Booking';
import styles from './BookingCard.module.css';
import { format } from 'date-fns';
import { Card, CardMedia, Box, CardContent, Typography } from '@mui/material';

const imgUrl = 'https://via.placeholder.com/200?text=Omega%20365%20Design%20AS';

export default function BookingCard({
    booking,
    onClick,
}: {
    booking: Booking;
    onClick: () => void;
}) {
    let { fromDate, toDate, entity } = booking;
    let fromHours: string = fromDate ? format(fromDate, 'HH:mm') : '';
    let toHours: string = toDate ? format(toDate, 'HH:mm') : '';

    return (
        <Card onClick={onClick}>
            <Box
                sx={{ height: '80px' }}
                display="grid"
                gridTemplateColumns="2fr 1fr"
                gridTemplateRows="auto"
            >
                <CardContent>
                    <Typography fontWeight="bold">
                        {entity ? entity.name : null}
                    </Typography>
                    <Typography fontSize="14px">
                        {fromHours} - {toHours}
                    </Typography>
                </CardContent>
                <CardMedia
                    sx={{ width: '100%', height: '100%' }}
                    component="img"
                    src={entity ? `${entity.imageUri}` : imgUrl}
                />
            </Box>
        </Card>
    );
}
