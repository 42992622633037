import Timeslot from './Timeslot';
import Image from './Image';

export default class Entity {
  id: number | undefined;
  name: string | undefined;
  price: number | undefined;
  timeslots: Timeslot[] | undefined;
  imageUri: string | undefined;
  images: Image[] | undefined;
  htmlContent: string | undefined;

  static fromJson(json: any): Entity {
    let e = new Entity();
    e.id = json['id'];
    e.name = json['name'];
    e.price = json['price'];
    e.htmlContent = json['htmlContent'];

    if (this && 'host' in this && json['imageUri']) {
      e.imageUri = (this as any).host + json['imageUri'];
    } else {
      e.imageUri = json['imageUri'];
    }

    if (json['images']) {
      if (this && 'host' in this) {
        e.images = json['images']?.map((i: any) =>
          Image.fromJson.bind({ host: (this as any).host })(i)
        );
      } else {
        e.images = json['images']?.map((i: any) => Image.fromJson(i));
      }
    }

    if (json['timeslots']) {
      e.timeslots = json['timeslots']?.map((t: any) => Timeslot.fromJson(t));
    }

    return e;
  }

  static async getAll(host: string): Promise<Entity[]> {
    let response = await fetch(`${host}/api/entities`);
    let result = await response.json();

    return result.map((e: any) => Entity.fromJson.bind({ host })(e));
  }

  static async createOrUpdateEntity(
    e: Entity,
    host: string,
    jwtToken: string
  ): Promise<Entity> {
    let response = await fetch(`${host}/api/entities`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(e),
    });

    let result = await response.json();

    return Entity.fromJson(result);
  }

  async delete(host: string, jwtToken: string): Promise<void> {
    let response = await fetch(`${host}/api/entities/${this.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    let result = await response.json();

    if (!('success' in result)) {
      throw new Error('Could not delete Entity!');
    }
  }
}
