import {
  Box,
  CardMedia,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { format } from 'date-fns';
import { useNavigate, useOutlet, useOutletContext } from 'react-router-dom';
import Booking from '../../Models/Booking';
import { useTranslation } from 'react-i18next';

const imgUrl = 'https://via.placeholder.com/200?text=Omega%20365%20Design%20AS';

export default function TableView() {
  let bookings = useOutletContext<Booking[]>();
  let navigate = useNavigate();
  let { t } = useTranslation();

  return (
    <Box>
      <Box p={4} display="flex" justifyContent="center" alignItems="center">
        <Typography fontSize="14px" fontStyle="italic">
          {t('descriptions.table_module')}
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableCell>{t('labels.entity')}</TableCell>
            <TableCell>{t('labels.from')}</TableCell>
            <TableCell>{t('labels.to')}</TableCell>
          </TableHead>
          <TableBody>
            {bookings
              .sort(
                (a: Booking, b: Booking) =>
                  a.fromDate!.getTime() - b.fromDate!.getTime()
              )
              .map((booking: Booking) => (
                <TableRow
                  onClick={() =>
                    navigate('/bookings/settings', { state: booking })
                  }
                >
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <div>
                        {/* <CardMedia
                        sx={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "5px",
                        }}
                        component="img"
                        src={
                          booking!.entity
                            ? `${process.env.REACT_APP_HOST}${booking.entity.imageUri}`
                            : imgUrl
                        }
                      /> */}
                      </div>
                      <Typography marginLeft="15px" fontWeight="bold">
                        {booking.entity!.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {format(booking.fromDate!, 'yyyy-MM-dd HH:mm')}
                  </TableCell>
                  <TableCell>
                    {format(booking.toDate!, 'yyyy-MM-dd HH:mm')}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
