export default class Duration {
  startDate: Date | undefined;
  endDate: Date | undefined;
  availableSpots: number | undefined;

  static fromJson(json: any): Duration {
    let d = new Duration();
    d.startDate = new Date(json["startDate"]);
    d.endDate = new Date(json["endDate"]);
    d.availableSpots = json["availableSpots"];

    return d;
  }
}
