import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from 'react-i18next';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import useBookingRepositoryHook from '../../booking-repository-hook';
import Entity from '../../Models/Entity';

export default function ContentEditor() {
  const [value, setValue] = useState('');
  const location: any = useLocation();
  let { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let { createOrUpdateEntity } = useBookingRepositoryHook();

  const mutation = useMutation(createOrUpdateEntity, {
    onSuccess: () => {
      queryClient.invalidateQueries(['entities']);
      navigate('/entities');
    },
  });

  useEffect(() => {
    if (location.state.htmlContent) {
      setValue(location.state.htmlContent);
    }
  }, [location]);

  function onSave() {
    mutation.mutate(
      Entity.fromJson({
        id: location.state.id,
        name: location.state.name,
        htmlContent: value,
        price: location.state.price ? Number.parseInt(location.state.price) : 0,
      })
    );
  }

  return (
    <>
      <ReactQuill theme="snow" value={value} onChange={setValue} />
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button onClick={onSave} startIcon={<SaveIcon />}>
          {t('buttons.save')}
        </Button>
      </Box>
    </>
  );
}
