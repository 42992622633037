import React, { useEffect } from "react";
import {
  TextField,
  Box,
  Typography,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Add";
import { useState } from "react";
import Entity from "../../Models/Entity";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { format } from "date-fns";
import RuleGenerator from "./RuleGenerator";
import Timeslot from "../../Models/Timeslot";
import { useTranslation } from "react-i18next";

import { FiCopy, FiRepeat } from "react-icons/fi";
import useBookingRepositoryHook from "../../booking-repository-hook";

export default function EditTimeslot() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location: any = useLocation();
  let { t } = useTranslation();
  let { id } = useParams<{ id: string }>();
  let { createTimeslot, updateTimeslot } = useBookingRepositoryHook();

  const mutation = useMutation(
    (t: Timeslot) =>
      t.id ? updateTimeslot(t) : createTimeslot(t, location.state.entity!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["entities"]);
        navigate("/entities");
      },
    }
  );

  /* const mutationDelete = useMutation((e: Entity) => e.delete(), {
    onSuccess: () => {
      queryClient.invalidateQueries(["entities"]);
      navigate("/entities");
    },
  }); */

  useEffect(() => {
    if (location.state && location.state.name) {
      setName(location.state.name);
    }

    if (location.state && location.state.id) {
      setName(location.state.name);
    }
  }, [location.state]);

  let [name, setName] = useState<string | null>(location.state.name);
  let [notificationMessage, setNotificationMessage] = useState<string | null>(
    location.state.notificationMessage
  );
  let [startDateString, setStartDateString] = useState<string | null>(
    format(location.state.startDate, "yyyy-MM-dd HH:mm")
  );
  let [endDateString, setEndDateString] = useState<string | null>(
    format(location.state.endDate, "yyyy-MM-dd HH:mm")
  );
  let [spots, setSpots] = useState<number>(location.state.spots);
  let [repeat, setRepeat] = useState<boolean>(location.state.repeat ?? false);
  let [updatedRule, setUpdatedRule] = useState<string>(location.state.rule);
  let [calendarDaysLimit, setCalendarDaysLimit] = useState(
    location.state.calendarDaysLimit
  );

  const onSave = () => {
    let timeslot: Timeslot = Timeslot.fromJson({
      id: location.state.id ?? null,
      name,
      notificationMessage,
      startDate: startDateString ? new Date(startDateString) : null,
      endDate: endDateString ? new Date(endDateString) : null,
      repeat,
      rule: updatedRule ?? "",
      spots: spots,
      calendarDaysLimit,
    });

    mutation.mutate(timeslot);

    console.log(timeslot);
  };

  const onDelete = () => {
    /* mutationDelete.mutate(
      Entity.fromJson({
        id: location.state.id,
        name,
        price: price ? Number.parseInt(price) : 0,
      })
    ); */
  };

  const onDuplicate = () => {
    let tsCopy = { ...location.state };
    tsCopy.id = null;
    tsCopy.name = tsCopy.name + " kopi";

    navigate("/timeslot/0", {
      state: tsCopy,
      replace: true,
    });

    console.log(tsCopy);
  };

  const onRuleGeneratorInitAndchange = (rule: string) => setUpdatedRule(rule);

  return (
    <FormGroup>
      <Box>
        {location.state && location.state.id && (
          <TextField
            placeholder="ID"
            label="ID"
            disabled
            variant="filled"
            sx={{ width: "100%", marginBottom: 2 }}
            value={location.state.id}
          />
        )}
        <TextField
          label={t("labels.name")}
          variant="filled"
          sx={{ width: "100%", marginBottom: 2 }}
          value={name}
          onChange={({ target }) => setName(target.value)}
        />
        <TextField
          type="number"
          label={t("labels.price")}
          helperText="Prisen er satt automatisk, men kan justeres."
          variant="filled"
          sx={{ width: "100%", marginBottom: 2 }}
          value={location.state.entity.price}
          onChange={({ target }) => setEndDateString(target.value)}
        />
        <TextField
          type="datetime-local"
          label={t("labels.from_date_time")}
          variant="filled"
          sx={{ width: "100%", marginBottom: 2 }}
          value={startDateString}
          onChange={({ target }) => setStartDateString(target.value)}
        />
        <TextField
          type="datetime-local"
          label={t("labels.to_date_time")}
          variant="filled"
          sx={{ width: "100%", marginBottom: 2 }}
          value={endDateString}
          onChange={({ target }) => setEndDateString(target.value)}
        />
        <TextField
          type="number"
          label={t("labels.available_spots")}
          variant="filled"
          sx={{ width: "100%", marginBottom: 2 }}
          value={spots}
          onChange={({ target }) => setSpots(Number.parseInt(target.value))}
        />
        <TextField
          type="text"
          label={t("labels.notificationMessage")}
          variant="filled"
          sx={{ width: "100%", marginBottom: 2 }}
          value={notificationMessage}
          onChange={({ target }) => setNotificationMessage(target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox checked={repeat} onClick={() => setRepeat(!repeat)} />
          }
          label={
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "70px",
              }}
            >
              {t("labels.repeat")} <FiRepeat opacity={0.5} />
            </span>
          }
        />
        {repeat && (
          <RuleGenerator
            rule={location.state.rule}
            onInitAndChange={onRuleGeneratorInitAndchange}
          />
        )}
        {repeat && (
          <TextField
            type="number"
            label={t("labels.calendar_days_limit")}
            variant="filled"
            helperText="Eksempel: Om du kun ønsker å vise en uke med tilgjengelige dager i kalenderen legger du inn verdien 7."
            sx={{ width: "100%", marginBottom: 2 }}
            value={calendarDaysLimit}
            onChange={({ target }) => setCalendarDaysLimit(target.value)}
          />
        )}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={onSave} startIcon={<SaveIcon />}>
            {t("buttons.save")}
          </Button>

          {location.state.id && (
            <Button
              onClick={onDuplicate}
              color="warning"
              startIcon={<FiCopy />}
            >
              {t("buttons.copy")}
            </Button>
          )}
          {location.state.id && (
            <Button onClick={onDelete} color="error" startIcon={<SaveIcon />}>
              {t("buttons.delete")}
            </Button>
          )}
        </Box>
      </Box>
    </FormGroup>
  );
}
