import React, { useEffect, useState } from 'react';
import styles from './Entity.module.css';
import { Box, Card, CardMedia } from '@mui/material';
import Image from '../../Models/Image';
import { useLocation } from 'react-router-dom';
import Entity from '../../Models/Entity';
import GalleryUploadButton from './GalleryUploadButton';
import { useTranslation } from 'react-i18next';
import useBookingRepositoryHook from '../../booking-repository-hook';
import { useAppStore } from '../../App';

export default function Gallery() {
  let location: any = useLocation();
  let [images, setImages] = useState<Image[] | null>(location.state.images);
  let [isLoading, setIsLoading] = useState<boolean>(false);
  let { t } = useTranslation();
  let { uploadImage } = useBookingRepositoryHook();
  let { host } = useAppStore((state) => state);

  const onSelect = ({ fileType, dataUrl }: any) => {
    setIsLoading(true);
    let newImage = Image.fromJson({
      imageFileType: fileType,
      imageDataUrl: dataUrl,
    });
    uploadImage(newImage, location.state).then((image) => {
      let newList: Image[] = [];

      if (images != null) {
        newList = [...images, image];
      } else {
        newList = [image];
      }

      setTimeout(() => {
        setImages(newList);
        setIsLoading(false);
      }, 2000);
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <div className={styles.gallery}>{images && images.map(ImageBox)}</div>
      <GalleryUploadButton isLoading={isLoading} onSelect={onSelect} />
    </Box>
  );
}

function ImageBox(image: Image) {
  return (
    <Card variant="outlined" className={styles.image}>
      <CardMedia
        component="img"
        src={`${image.resourceUri}`}
        sx={{ height: '200px' }}
      />
    </Card>
  );
}
