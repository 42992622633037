import React, { useState } from "react";
import { Tabs, Tab, CircularProgress } from "@mui/material";
import styles from "./Booking.module.css";
import { Outlet } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import Booking from "../../Models/Booking";
import { useTranslation } from "react-i18next";
import useBookingRepositoryHook from "../../booking-repository-hook";

const Overview = () => {
  let location = useLocation();
  let navigate = useNavigate();
  let { getBookings } = useBookingRepositoryHook();
  let queryClient = useQueryClient();
  let { data, isLoading } = useQuery(["bookings"], getBookings);
  let { t } = useTranslation();

  let onBookingClick: any = (e: Booking) =>
    navigate("/bookings/settings", { state: e });

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles.overview}>
      <Tabs sx={{ padding: "0 20px" }} value={location.pathname}>
        <Tab
          value="/bookings/overview"
          label={t("tabs.timeline")}
          onClick={() =>
            navigate("/bookings/overview", { state: location.state })
          }
        />
        <Tab
          value="/bookings/overview/tableview"
          label={t("tabs.table")}
          onClick={() =>
            navigate("/bookings/overview/tableview", { state: location.state })
          }
        />
        <Tab
          value="/bookings/overview/calendar"
          onClick={() =>
            // navigate("/bookings/overview/calendar", { state: location.state })
            alert(t("descriptions.not_implemented"))
          }
          label={t("tabs.calendar")}
        />
      </Tabs>
      <Outlet context={data} />
    </div>
  );
};

export default Overview;
