import React, { useEffect } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import styles from "./Booking.module.css";
import { Outlet, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../App";
import useBookingRepositoryHook from "../../booking-repository-hook";

const BookingModule = () => {
  let location = useLocation();
  let { t } = useTranslation();
  let user = useAppStore((state) => state.user);
  let navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/signin");
    }
  }, [user]);

  return (
    <Box className={styles.common}>
      <h1 className={styles.h1}>{t("pages.bookings")}</h1>
      <Outlet />
    </Box>
  );
};

export default BookingModule;
