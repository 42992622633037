import { Box } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Entity from "../../Models/Entity";
import Calendar from "react-calendar";
import { isSameMonth, isToday, isWithinInterval } from "date-fns";
import "react-calendar/dist/Calendar.css";
import "./react-calendar-custom.css";
import { useTranslation } from "react-i18next";

import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import Timeslot from "../../Models/Timeslot";
import Duration from "../../Models/Duration";
import { isSameDay } from "date-fns/esm";

interface EntityTimeslotsCalendarProps {
  entities: Entity[];
}

export default function EntityTimeslotsCalendar() {
  let location = useLocation();
  let { timeslots }: any = location.state;
  let durations: Duration[] = timeslots.flatMap((t: Timeslot) => t.durations);
  let { t } = useTranslation();

  function hideTileIf({ activeStartDate, date }: any): boolean {
    return !isSameMonth(activeStartDate, date);
  }

  function setTileClass({ date }: any) {
    console.log(durations);
    return durations.some((d) => {
      if (d.startDate && d.endDate) {
        return isSameDay(date, d.startDate);
      } else {
        return false;
      }
    })
      ? "available-spots"
      : "";
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Calendar tileClassName={setTileClass} tileDisabled={hideTileIf} />
    </Box>
  );
}

/* function Cell({ activeStartDate, date }: any) {
  return <Box>{date.getDate()}</Box>;
} */
