import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface RuleGeneratorProps {
  rule: string | null;
  onInitAndChange: (rule: string) => void;
}

function parseRuleString(rule: string): Map<string, any> {
  let entries = rule.split(";").map((v) => v.split("="));
  return new Map<string, any>(entries as Iterable<[string, any]>);
}

export default function RuleGenerator({
  rule,
  onInitAndChange,
}: RuleGeneratorProps) {
  let [state, setState] = useState(rule ?? "FREQ=DAILY;INTERVAL=1;COUNT=1");
  let [mapOfRules, setMapOfRules] = useState<Map<string, any> | null>(null);
  let { t } = useTranslation();

  useEffect(() => {
    let res = parseRuleString(state);
    setMapOfRules(res);
  }, [state]);

  function setInterval({ target }: SelectChangeEvent) {
    setMapOfRules((m) => {
      m?.set("INTERVAL", target.value);

      return new Map(m?.entries());
    });
  }

  function setFreq({ target }: SelectChangeEvent) {
    setMapOfRules((m) => {
      m?.set("FREQ", target.value);

      if (target.value != "WEEKLY") {
        m?.delete("BYDAY");
      }

      return new Map(m?.entries());
    });
  }

  function setCount({ target }: any) {
    setMapOfRules((m) => {
      m?.set("COUNT", target.value);
      return new Map(m?.entries());
    });
  }

  function isDayChecked(day: string) {
    if (mapOfRules?.has("BYDAY")) {
      return (mapOfRules.get("BYDAY") as string).includes(day);
    } else {
      return false;
    }
  }

  function toggleDay(day: string) {
    setMapOfRules((m) => {
      if (m?.has("BYDAY")) {
        let val: string = m.get("BYDAY");
        let list = val == "" ? [] : val.split(",");

        if (list.includes(day)) {
          list = list.filter((e) => e != day);
        } else {
          list.push(day);
        }

        if (list.length == 0) {
          m.delete("BYDAY");
        } else {
          val = list.join(",");
          m.set("BYDAY", val);
        }
      } else {
        m?.set("BYDAY", day);
      }

      return new Map(m?.entries());
    });
  }

  useEffect(() => onInitAndChange(convertMapToRule()), [mapOfRules]);

  function convertMapToRule(): string {
    let entries = mapOfRules?.entries();

    if (entries != null) {
      let res = Array.from(entries);
      let newList = res.flatMap(([key, value]) => `${key}=${value}`);

      return newList.join(";");
    }

    return "";
  }

  function getFreqLabel(freq: string) {
    switch (freq) {
      case "DAILY":
        return t("labels.daily");
      case "WEEKLY":
        return t("labels.weekly");
      case "MONTHLY":
        return t("labels.monthly");
      case "YEARLY":
        return t("labels.yearly");
      default:
        return "N/A";
    }
  }

  function getCountLabel(freq: string) {
    switch (freq) {
      case "DAILY":
        return t("labels.number_of_days");
      case "WEEKLY":
        return t("labels.number_of_weeks");
      case "MONTHLY":
        return t("labels.number_of_months");
      case "YEARLY":
        return t("labels.number_of_years");
      default:
        return "N/A";
    }
  }

  if (!mapOfRules) return null;

  return (
    <>
      <FormGroup>
        <Select
          value={mapOfRules.get("FREQ")}
          label="Frekvens"
          onChange={setFreq}
          sx={{ marginBottom: 1 }}
        >
          <MenuItem value="DAILY">{t("labels.daily")}</MenuItem>
          <MenuItem value="WEEKLY">{t("labels.weekly")}</MenuItem>
          <MenuItem value="MONTHLY">{t("labels.monthly")}</MenuItem>
          <MenuItem value="YEARLY">{t("labels.yearly")}</MenuItem>
        </Select>
      </FormGroup>
      {mapOfRules.get("FREQ") == "WEEKLY" && (
        <FormGroup sx={{ display: "flex", flexDirection: "row", margin: 2 }}>
          <FormControlLabel
            label={t("labels.mo")}
            control={
              <Checkbox
                checked={isDayChecked("MO")}
                onClick={() => toggleDay("MO")}
              />
            }
            labelPlacement="bottom"
          />
          <FormControlLabel
            label={t("labels.tu")}
            control={
              <Checkbox
                checked={isDayChecked("TU")}
                onClick={() => toggleDay("TU")}
              />
            }
            labelPlacement="bottom"
          />
          <FormControlLabel
            label={t("labels.we")}
            control={
              <Checkbox
                checked={isDayChecked("WE")}
                onClick={() => toggleDay("WE")}
              />
            }
            labelPlacement="bottom"
          />
          <FormControlLabel
            label={t("labels.th")}
            control={
              <Checkbox
                checked={isDayChecked("TH")}
                onClick={() => toggleDay("TH")}
              />
            }
            labelPlacement="bottom"
          />
          <FormControlLabel
            label={t("labels.fr")}
            control={
              <Checkbox
                checked={isDayChecked("FR")}
                onClick={() => toggleDay("FR")}
              />
            }
            labelPlacement="bottom"
          />
          <FormControlLabel
            label={t("labels.sa")}
            control={
              <Checkbox
                checked={isDayChecked("SA")}
                onClick={() => toggleDay("SA")}
              />
            }
            labelPlacement="bottom"
          />
          <FormControlLabel
            label={t("labels.su")}
            control={
              <Checkbox
                checked={isDayChecked("SU")}
                onClick={() => toggleDay("SU")}
              />
            }
            labelPlacement="bottom"
          />
        </FormGroup>
      )}
      <FormGroup sx={{ marginBottom: 3 }}>
        <Select
          value={mapOfRules.get("INTERVAL")}
          label="Intervall"
          onChange={setInterval}
        >
          <MenuItem value="1">
            {t("labels.every")} ({getFreqLabel(mapOfRules.get("FREQ"))})
          </MenuItem>
          <MenuItem value="2">
            {t("labels.every_other")} ({getFreqLabel(mapOfRules.get("FREQ"))})
          </MenuItem>
          <MenuItem value="3">
            {t("labels.every_third")} ({getFreqLabel(mapOfRules.get("FREQ"))})
          </MenuItem>
          <MenuItem value="4">
            {t("labels.every_fourth")} ({getFreqLabel(mapOfRules.get("FREQ"))})
          </MenuItem>
        </Select>
      </FormGroup>
      <FormGroup sx={{ marginBottom: 3 }}>
        <TextField
          type="number"
          label={getCountLabel(mapOfRules.get("FREQ"))}
          value={mapOfRules.get("COUNT")}
          onChange={setCount}
          helperText={
            mapOfRules.get("FREQ") == "WEEKLY"
              ? t("labels.if_number_of_weeks_selected")
              : ""
          }
        />
      </FormGroup>
    </>
  );
}
