import React from "react";
import styles from "./Timeslot.module.css";
import { useNavigate, Outlet, useLocation, useParams } from "react-router-dom";
import { Typography, Box, IconButton, Tabs, Tab } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";

const Timeslot = () => {
  let navigate = useNavigate();
  let location: any = useLocation();
  let { t } = useTranslation();
  let { id } = useParams<{ id: string }>();

  return (
    <div className={styles.common}>
      <h1 className={styles.h1}>Tidsrom</h1>
      <div>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography component="div" sx={{ fontSize: 40, fontWeight: 400 }}>
            {location.state.name}
          </Typography>
        </Box>
        <Tabs value={location.pathname} sx={{ marginBottom: 2 }}>
          <Tab
            label={true ? t("tabs.settings") : t("tabs.create")}
            value={`/timeslot/${id}`}
            onClick={() =>
              navigate(`/timeslot/${id}`, { state: location.state })
            }
          ></Tab>
          {true && (
            <Tab
              label={t("tabs.durations")}
              value={`/timeslot/${id}/durations`}
              onClick={() => {
                navigate(`/timeslot/${id}/durations`, {
                  state: location.state,
                });
              }}
            ></Tab>
          )}
        </Tabs>
        <Outlet />
      </div>
    </div>
  );
};

export default Timeslot;
