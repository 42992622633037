export default class User {
  username: string | undefined;
  roles: string[] | undefined;
  jwtToken: string | undefined;

  static fromJson(json: any): User {
    let u = new User();
    u.username = json["username"];
    u.roles = json["roles"];
    u.jwtToken = json["token"];
    return u;
  }

  hasRole(role: string): boolean {
    return this.roles != null && this.roles.includes(role);
  }

  /* static async signInWithUsernameAndPassword(
    username: string,
    password: string
  ): Promise<User> {
    let formData = new URLSearchParams();
    formData.append("grant_type", "password");
    formData.append("client_id", `${process.env.REACT_APP_AUTH_CLIENT_ID}`);
    formData.append("username", username);
    formData.append("password", password);

    let res = await fetch(`${process.env.REACT_APP_AUTH_URL}`, {
      method: "POST",
      body: formData,
    });

    if (res.status != 200) throw new Error("Could not authenticate!");

    let jwt = (await res.json())["access_token"];
    let data = JSON.parse(atob(jwt.split(".")[1]));
    let user = User.fromJson({
      username: data["preferred_username"],
      roles: data["realm_access"]["roles"],
    });

    return user;
  } */

  static async signInWithUsernameAndPassword(
    host: string,
    username: string,
    password: string
  ): Promise<User> {
    // This version uses internal auth instead of OAuth/Keycloak.
    let res = await fetch(`${host}/api/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });

    if (res.status != 200) throw new Error("Could not authenticate!");

    let jwt = (await res.json())["token"];
    let data = JSON.parse(atob(jwt.split(".")[1]));
    let user = User.fromJson({
      username: data["account"]["username"],
      roles: data["account"]["roles"],
      token: jwt,
    });

    return user;
  }

  static async getUserFromJwtToken(token: string) {
    let data = JSON.parse(atob(token.split(".")[1]));
    console.log(data);

    if ((data["exp"] as number) * 1000 < Date.now())
      throw new Error("Token expired!");

    let user = User.fromJson({
      username: data["account"]["username"],
      roles: data["account"]["roles"],
      token,
    });

    return user;
  }
}
