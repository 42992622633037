import { useState } from "react";
import { useAppStore } from "./App";
import Booking from "./Models/Booking";
import Entity from "./Models/Entity";
import Image from "./Models/Image";
import Timeslot from "./Models/Timeslot";
import { addDays, isThisWeek } from "date-fns";

function useBookingRepositoryHook() {
  let { user, host } = useAppStore((state) => state);

  return {
    getTimeslotById: (id: string) => Timeslot.get(id, host!, user!.jwtToken!),
    createTimeslot: (timeslot: Timeslot, entity: Entity) =>
      timeslot.create(entity, host!, user!.jwtToken!),
    updateTimeslot: (timeslot: Timeslot) =>
      timeslot.update(host!, user!.jwtToken!),
    updateTimeslotByIdAndFieldValue: (
      id: string,
      fields: { [key: string]: any }
    ) => Timeslot.update(id, fields, host!, user!.jwtToken!),
    createOrUpdateEntity: (entity: Entity): Promise<Entity> =>
      Entity.createOrUpdateEntity(entity, host!, user!.jwtToken!),
    getBookings: () => Booking.getAll(host!, user!.jwtToken!),
    getEntities: () => Entity.getAll(host!),
    uploadImage: (image: Image, entity: Entity) =>
      image.createOrUpdate(entity, host!, user!.jwtToken!),
    deleteEntity: (e: Entity) => e.delete(host!, user!.jwtToken!),
    getDailyBookingCountThisWeek: () =>
      Booking.getAll(host!, user!.jwtToken!).then((bookings: Booking[]) => {
        let entityBookings: Map<String, Booking[]> = new Map();
        let data: Array<{
          id: string;
          label: string;
          value: number;
          color: string;
        }> = [];

        bookings.forEach((b: Booking) => {
          if (entityBookings.has(b.entity!.name!)) {
            entityBookings.get(b.entity!.name!)?.push(b);
          } else {
            entityBookings.set(b.entity!.name!, [b]);
          }
        });

        entityBookings.forEach((bookings: Booking[], key: String) => {
          if (bookings.some((b: Booking) => isThisWeek(b.fromDate!))) {
            data.push({
              id: key.toString(),
              label: key.toString(),
              value: bookings.filter((b: Booking) => isThisWeek(b.fromDate!))
                .length,
              color: "hsl(0, 70%, 50%)",
            });
          }
        });

        return data;
      }),
  };
}

export default useBookingRepositoryHook;
