import React from "react";
import styles from "./BookingCard.module.css";
import {
  TextField,
  Box,
  Typography,
  Button,
  CardMedia,
  Card,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Add";
import { FiUser } from "react-icons/fi";
import { useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

export default function EditBooking() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location: any = useLocation();
  let { t } = useTranslation();

  /* const mutation = useMutation(Entity.createOrUpdateEntity, {
      onSuccess: () => {
        queryClient.invalidateQueries(["entities"]);
        navigate("/entities");
      },
    });
  
    const mutationDelete = useMutation((e: Entity) => e.delete(), {
      onSuccess: () => {
        queryClient.invalidateQueries(["entities"]);
        navigate("/entities");
      },
    }); */

  /* let [name, setName] = useState<string | null>(location.state.name);
    let [price, setPrice] = useState<string | null>(location.state.price); */

  /* const onSave = () => {
      mutation.mutate(
        Entity.fromJson({
          id: location.state.id,
          name,
          price: price ? Number.parseInt(price) : 0,
          imageDataUrlType: currentImage?.split(",")[0],
          imageDataUrl: currentImage?.split(",")[1],
        })
      );
    };
   */
  /* const onDelete = () => {
      mutationDelete.mutate(
        Entity.fromJson({
          id: location.state.id,
          name,
          price: price ? Number.parseInt(price) : 0,
        })
      );
    }; */

  return (
    <Box>
      <TextField
        placeholder="ID"
        label="ID"
        disabled
        variant="filled"
        sx={{ width: "100%", marginBottom: 2 }}
        value={location.state.id}
      />
      <TextField
        label={t("labels.from_date_time")}
        variant="filled"
        disabled
        sx={{ width: "100%", marginBottom: 2 }}
        value={format(location.state.fromDate, "yyyy-MM-dd HH:mm")}
      />
      <TextField
        label={t("labels.to_date_time")}
        variant="filled"
        disabled
        sx={{ width: "100%", marginBottom: 2 }}
        value={format(location.state.toDate, "yyyy-MM-dd HH:mm")}
      />

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* <Button onClick={() => {}} startIcon={<SaveIcon />}>
          Lagre
        </Button>
        <Button onClick={() => {}} color="error" startIcon={<SaveIcon />}>
          Slett
        </Button> */}
      </Box>
      <Button
        onClick={() => {
          if (
            "customer" in location.state &&
            location.state.customer != undefined
          ) {
            navigate("/customer", { state: location.state.customer });
          }
        }}
        variant="contained"
        startIcon={<FiUser color="white" size="28px" />}
      >
        {t("buttons.show_customer_data")}
      </Button>
      {location.state.metaData != null && (
        <div style={{ marginTop: "20px" }}>
          {location.state.metaData.map(tryParse).map(MetaDataRow)}
        </div>
      )}
    </Box>
  );
}

function MetaDataRow({ display, content }: MetaDataValue) {
  return (
    <div className={styles.metaRow}>
      <div className={styles.metaDisplay}>{display}</div>
      <div className={styles.metaContent}>{content}</div>
    </div>
  );
}

function tryParse(value: string): MetaDataValue {
  let res = value.split(":");

  let comp: MetaDataValue = { display: "N/A", content: "" };

  if (res.length == 2) {
    comp.display = res[0];
    comp.content = res[1];
  } else {
    comp.content = value;
  }

  return comp;
}

interface MetaDataValue {
  display: string;
  content: string;
}
