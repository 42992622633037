import React, { useEffect, useState } from 'react';
import Entity from '../../Models/Entity';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Card,
    CardContent,
    Typography,
    CardMedia,
    Button,
} from '@mui/material';
import styles from './Entity.module.css';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';

// Feather icon
import { FiPlus } from 'react-icons/fi';
import useBookingRepositoryHook from '../../booking-repository-hook';
import { useAppStore } from '../../App';

export default function Home() {
    let [entities, setEntities] = useState(null);
    let queryClient = useQueryClient();
    let { getEntities } = useBookingRepositoryHook();
    let { data, isLoading } = useQuery(['entities'], getEntities);
    let navigate = useNavigate();
    let { t } = useTranslation();

    let onEntityClick: any = (e: Entity) =>
        navigate('/entities/settings', { state: e });

    if (isLoading) {
        return (
            <div className={styles.spinner}>
                <CircularProgress sx={{ color: 'white' }} />
            </div>
        );
    }

    return (
        <div className={styles.content}>
            {data?.map((e: Entity) => (
                <EntityCard key={e.id} entity={e} onClick={onEntityClick} />
            ))}
            <Button
                onClick={() => navigate('/entities/create')}
                variant="contained"
                startIcon={<SaveIcon />}
            >
                {t('buttons.create_entity')}
            </Button>
        </div>
    );
}

function EntityCard({
    entity,
    onClick,
}: {
    entity: Entity;
    onClick: (args: Entity) => void;
}) {
    // let navigate = useNavigate();

    return (
        <Card
            onClick={() => onClick(entity)}
            variant="outlined"
            sx={{ marginBottom: 2, display: 'flex' }}
        >
            <CardMedia
                component="img"
                sx={{ width: 150, height: 100 }}
                src={
                    entity.imageUri
                        ? `${entity.imageUri}`
                        : '/images/nophoto.jpg'
                }
            />
            <CardContent>
                <Typography
                    component="div"
                    sx={{ fontSize: 20, fontWeight: 400 }}
                >
                    {entity.name}
                </Typography>
            </CardContent>
        </Card>
    );
}
