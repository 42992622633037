import { Box, IconButton, TextField, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Customer.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";

export default function Customer() {
  let location = useLocation();
  let navigate = useNavigate();
  let customerData = location.state;
  let { t } = useTranslation();

  return (
    <div className={styles.common}>
      <h1 className={styles.h1}>{t("pages.customer_data")}</h1>
      <div className={styles.content}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography component="div" sx={{ fontSize: 20, fontWeight: 400 }}>
            {t("labels.booking")}
          </Typography>
        </Box>
        <TextField
          label={t("labels.name")}
          variant="filled"
          disabled
          value={(customerData! as any).name}
        />
        <TextField
          label={t("labels.email")}
          variant="filled"
          disabled
          value={(customerData! as any).email}
        />
        <TextField
          label={t("labels.phone")}
          variant="filled"
          disabled
          value={(customerData! as any).phoneNumber}
        />
      </div>
    </div>
  );
}
