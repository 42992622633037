import React, { useEffect, useRef } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import FileOpenIcon from "@mui/icons-material/CloudUpload";
import Compressor from "compressorjs";
import { useTranslation } from "react-i18next";

interface ImageSelectorProps {
  isLoading?: boolean;
  onSelect: ({
    fileType,
    dataUrl,
  }: {
    fileType: string;
    dataUrl: string;
  }) => void;
}

export default function GalleryUploadButton({
  onSelect,
  isLoading = false,
}: ImageSelectorProps) {
  let inputRef = useRef(null);
  let { t } = useTranslation();

  const btnClick = () => (inputRef.current as any)?.click();

  useEffect(() => {
    (inputRef.current as any).onchange = ({ target }: any) => {
      let reader = new FileReader();

      new Compressor(target.files[0], {
        quality: 0.6,
        success: (file) => {
          reader.addEventListener("load", (e) => {
            let result = e.target?.result;
            let size = e.total;
            const bytesInKb = 1024;

            if (size / bytesInKb > 5000) {
              alert("Selected photo is too big, please choose one under ~1mb.");
            } else if (typeof result == "string") {
              let fileType = result.split(",")[0].split("/")[1].split(";")[0];
              let dataUrl = result.split(",")[1];
              onSelect({ fileType, dataUrl });
            }
          });
          reader.readAsDataURL(file);
        },
      });
    };
  }, []);

  return (
    <Box>
      <Button
        variant="contained"
        disabled={isLoading}
        startIcon={
          isLoading ? (
            <CircularProgress size="small" />
          ) : (
            <FileOpenIcon sx={{ fontSize: 40 }} />
          )
        }
        onClick={btnClick}
        sx={{ width: "100%" }}
      >
        {t("buttons.upload_photo")}
      </Button>
      <input hidden={true} type="file" ref={inputRef} accept=".jpg, .jpeg" />
    </Box>
  );
}
