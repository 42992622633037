import React, { useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import styles from "./Entity.module.css";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../App";

const EntityModule = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let { t } = useTranslation();
  let user = useAppStore((state) => state.user);

  useEffect(() => {
    if (!user) {
      navigate("/signin");
    }
  }, [user]);

  return (
    <div className={styles.common}>
      <h1 className={styles.h1}>{t("pages.entities")}</h1>
      <Outlet />
    </div>
  );
};

export default EntityModule;
