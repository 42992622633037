import Duration from "./Duration";
import Entity from "./Entity";

export default class Timeslot {
  id: string | undefined;
  name: string | undefined;
  repeat: boolean | undefined;
  rule: string | undefined;
  notificationMessage: string | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
  spots: number | undefined;
  durations: Duration[] | undefined;
  ignoredDates: Date[] | undefined;
  calendarDaysLimit: number | undefined;

  static fromJson(json: any): Timeslot {
    let t = new Timeslot();
    t.id = json["id"];
    t.name = json["name"];
    t.rule = json["rule"];
    t.startDate = new Date(json["startDate"]);
    t.endDate = new Date(json["endDate"]);
    t.repeat = json["repeat"];
    t.spots = json["spots"];
    t.calendarDaysLimit = json["calendarDaysLimit"];
    t.notificationMessage = json["notificationMessage"];

    if (json["ignoredDates"]) {
      t.ignoredDates = json["ignoredDates"].map(
        (dateString: string) => new Date(dateString)
      );
    }

    if (json["durations"]) {
      t.durations = json["durations"].map((d: any) => Duration.fromJson(d));
    }

    return t;
  }

  async create(e: Entity, host: string, jwtToken: string): Promise<Timeslot> {
    let response = await fetch(`${host}/api/entities/${e.id}/timeslots`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(this),
    });

    let result = await response.json();

    return Timeslot.fromJson(result);
  }

  static async get(id: string, host: string, jwtToken: string) {
    let response = await fetch(`${host}/api/timeslots/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    if (response.status != 200) throw new Error("Could not find Timeslot!");

    return Timeslot.fromJson(await response.json());
  }

  async update(host: string, jwtToken: string): Promise<Timeslot> {
    let response = await fetch(`${host}/api/timeslots/${this.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ ...this, id: null }),
    });

    let result = await response.json();

    return Timeslot.fromJson(result);
  }

  static async update(
    id: string,
    fields: { [key: string]: any },
    host: string,
    jwtToken: string
  ) {
    let response = await fetch(`${host}/api/timeslots/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify(fields),
    });

    let result = await response.json();

    return Timeslot.fromJson(result);
  }
}
