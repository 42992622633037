import React, { useEffect } from "react";
import { Card, Typography, Box, Button } from "@mui/material";
import Timeslot from "../../Models/Timeslot";
import { useLocation, useNavigate } from "react-router-dom";
import ThingyIcon from "@mui/icons-material/LockClockOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { isWithinInterval } from "date-fns";
import SaveIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

export default function Timeslots() {
  let location: any = useLocation();
  let navigate = useNavigate();
  let { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {location.state.timeslots.map((t: Timeslot) => (
        <TimeslotCard
          timeslot={t}
          onClick={() =>
            navigate(`/timeslot/${t.id}`, {
              state: { ...t, entity: location.state },
            })
          }
        />
      ))}
      <Button
        onClick={() =>
          navigate("/timeslot/0", {
            state: {
              ...Timeslot.fromJson({
                name: t("labels.timeslot_temp_name"),
                spots: 1,
                startDate: new Date(Date.now()),
                // seconds-hr * ms-per-sec * hours
                // this results in one hour
                endDate: new Date(Date.now() + 3600 * 1000 * 1),
              }),
              entity: location.state,
            },
          })
        }
        variant="contained"
        startIcon={<SaveIcon />}
      >
        {t("buttons.create_timeslot")}
      </Button>
    </Box>
  );
}

function TimeslotCard({
  timeslot,
  onClick,
}: {
  timeslot: Timeslot;
  onClick: () => void;
}) {
  return (
    <Card onClick={onClick} sx={{ display: "flex", marginBottom: 2 }}>
      <Box
        sx={{
          backgroundColor: "#005f76",
          width: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
        }}
      >
        <ThingyIcon sx={{ fontSize: 30 }} />
      </Box>
      <Box sx={{ padding: "10px", marginLeft: 1 }}>
        <Typography component="div" sx={{ fontSize: 20 }}>
          {timeslot.name}
        </Typography>
        <Typography component="div" sx={{ fontSize: 16 }}>
          {/* {isTimeslotActive(timeslot) && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <CheckCircleIcon sx={{ color: "#1b5e20", marginRight: "2px" }} />
              Aktiv
            </Box>
          )}
          {!isTimeslotActive(timeslot) && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IndeterminateCheckBoxIcon
                sx={{ color: "#b71c1c", marginRight: "2px" }}
              />
              Ikke aktiv
            </Box>
          )} */}
        </Typography>
      </Box>
    </Card>
  );
}

function isTimeslotActive(t: Timeslot): boolean {
  if (t.startDate && t.endDate) {
    return isWithinInterval(new Date(), {
      start: t.startDate,
      end: t.endDate,
    });
  }

  return false;
}
