import React, { useEffect, useState } from 'react';
import styles from './Menu.module.css';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { FiLogOut } from 'react-icons/fi';
import { useAppStore } from '../../App';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { RiShieldUserLine } from 'react-icons/ri';
import { Outlet, useNavigate } from 'react-router-dom';

export default function MenuModule() {
  let { user, signOut } = useAppStore((state) => ({
    user: state.user,
    signOut: state.signOut,
  }));
  let [minLeft, setMin] = useState<number | null>(null);
  let { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/signin');
    }
  }, [user]);

  useEffect(() => {
    setInterval(() => {
      if (user?.jwtToken) {
        let { exp }: { exp: number } = JSON.parse(
          atob(user.jwtToken.split('.')[1])
        );

        setMin(Math.round((exp * 1000 - Date.now()) / 1000 / 60));
      }
    }, 1000);
  }, []);

  return (
    <div className={styles.common}>
      <div className={styles.header}>
        <h1 className={styles.h1}>{t('pages.menu')}</h1>
      </div>
      <div className={styles.content}>
        <Outlet />
        <Box display="flex" justifyContent="center">
          {user && (
            <Button
              onClick={signOut}
              endIcon={<FiLogOut />}
              variant="contained"
            >
              {t('buttons.sign_out')}, {user.username} ({minLeft ?? '?'} min).
            </Button>
          )}
        </Box>
      </div>
    </div>
  );
}
