import React from "react";
import { Typography, Box, IconButton, Tabs, Tab } from "@mui/material";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import Entity from "../../Models/Entity";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";

export default function Settings() {
  let navigate = useNavigate();
  let location: any = useLocation();
  let { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        padding: "0 20px",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography component="div" sx={{ fontSize: 40, fontWeight: 400 }}>
          {t("labels.booking")}
        </Typography>
      </Box>
      <Tabs value={location.pathname} sx={{ marginBottom: 2 }}>
        <Tab
          label={t("tabs.settings")}
          value="/bookings/settings"
          onClick={() =>
            navigate("/entities/settings", { state: location.state })
          }
        ></Tab>
        <Tab
          label={t("tabs.orders")}
          value="/bookings/settings/orders"
          onClick={() =>
            // navigate("/bookings/settings/orders", { state: location.state })
            alert(t("descriptions.not_implemented"))
          }
        ></Tab>
      </Tabs>
      <Outlet />
    </Box>
  );
}
