import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./Timeline.module.css";
import { Box, Typography } from "@mui/material";
import {
  addDays,
  format,
  differenceInDays,
  isBefore,
  subDays,
  isToday,
  isTomorrow,
  isSameYear,
  isThisYear,
} from "date-fns";
import nb from "date-fns/locale/nb";
import BookingCard from "./BookingCard";
import { useNavigate, useOutletContext } from "react-router-dom";
import Booking from "../../Models/Booking";
import { isSameDay } from "date-fns";

let initList: number[] = new Array(10).fill(0);

export default function Timeline() {
  let navigate = useNavigate();
  const todayDate = new Date();
  let [dates, setDates] = useState<Date[]>(
    initList.map((_, i: number) => addDays(todayDate, i))
  );
  let bookings = useOutletContext<Booking[]>();

  return (
    <div className={styles.timeline}>
      {dates.map((date: Date, i: number) => (
        <TimelineElement
          date={date}
          isIntersectingCallback={(isIntersecting) => {
            if (isIntersecting && dates.length - 1 == i) {
              setDates((state: Date[]) => [...state, addDays(date, 1)]);
            }
          }}
          render={() => (
            <ListBookings
              onClick={(b: Booking) =>
                navigate("/bookings/settings", { state: b })
              }
              bookings={bookings.filter(({ fromDate, toDate }) =>
                fromDate && toDate ? isSameDay(date, fromDate) : false
              )}
            />
          )}
        />
      ))}
    </div>
  );
}

function ListBookings({
  bookings,
  onClick,
}: {
  bookings: Booking[];
  onClick: (b: Booking) => void;
}) {
  return (
    <Box display="grid" gap={1}>
      {bookings.map((b) => (
        <BookingCard booking={b} onClick={() => onClick(b)} />
      ))}
    </Box>
  );
}

function TimelineElement({
  date,
  render: Component,
  isIntersectingCallback,
}: {
  date: Date;
  render: React.FC;
  isIntersectingCallback: (val: boolean) => void;
}) {
  let ref = useRef<HTMLDivElement>(null);
  let isIntersecting = useIsInViewport(ref);

  /* useEffect(() => {
    if (ref && ref.current) {
      if (differenceInDays(date, new Date()) == 0) {
        ref.current.scrollIntoView();
      }
    }
  }, []); */

  useEffect(() => isIntersectingCallback(isIntersecting), [isIntersecting]);

  return (
    <Box
      ref={ref}
      className={styles.box}
      sx={{
        width: "100%",
      }}
    >
      <TimelineDateWindow date={date} />
      <Box
        sx={{
          marginLeft: 4,
          paddingTop: "32px",
          marginBottom: 4,
        }}
      >
        <Component />
      </Box>
    </Box>
  );
}

function getDateWindowLabel(date: Date) {
  let month = format(date, "MMM", { locale: nb })
    .toUpperCase()
    .trimEnd()
    .replace(".", "");
  let day = format(date, "d", { locale: nb })
    .toUpperCase()
    .trimEnd()
    .replace(".", "");
  let year = format(date, "yyyy", { locale: nb })
    .toUpperCase()
    .trimEnd()
    .replace(".", "");

  if (isToday(date)) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "72px",
        }}
      >
        <Typography fontWeight={300} fontSize="28px">
          I dag
        </Typography>
      </Box>
    );
  } else if (isTomorrow(date)) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography fontWeight={200} fontSize="28px" marginBottom="-10px">
          {day}
        </Typography>
        <Typography fontWeight={200} fontSize="28px">
          {month}
        </Typography>
      </Box>
    );
  } else if (isThisYear(date)) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography fontWeight={200} fontSize="28px" marginBottom="-10px">
          {day}
        </Typography>
        <Typography fontWeight={200} fontSize="28px">
          {month}
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography fontWeight={200} fontSize="28px" marginBottom="-10px">
          {day}
        </Typography>
        <Typography fontWeight={200} fontSize="28px" marginBottom="-10px">
          {month}
        </Typography>
        <Typography fontWeight={200} fontSize="28px">
          {year}
        </Typography>
      </Box>
    );
  }
}

function TimelineDateWindow({ date }: { date: Date }) {
  return (
    <div className={styles.dateWindow}>
      <div className={styles.date}>{getDateWindowLabel(date)}</div>
      <div
        className={[styles.dot, isToday(date) ? styles.pulse : null].join(" ")}
      ></div>
    </div>
  );
}

function useIsInViewport(ref: any) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
